import React from "react"
import { ActNormativ } from "../../utils/api/acteNormative"
import svgs from "../Dash/svgs"

type TabelPropTypes = {
  tipRaport?: number
  items: ActNormativ[]
  getDenumireForTip?: (tipRaport: number) => string
}

const defaultGetDenumireForTip = (tipRaport: number) => {
  switch (tipRaport) {
    case 0:
      return "Standard de Performanță - Furnizare"
    case 1:
      return "Standard de Performanță - Distribuție"
    case 2:
      return "Rapoarte privind soluționarea plângerilor privind furnizarea de gaze naturale"
    default:
      return "Eroare tip"
  }
}

export default function TabelActe({
  items,
  tipRaport,
  getDenumireForTip = defaultGetDenumireForTip,
}: TabelPropTypes) {
  return (
    <div>
      {tipRaport != null && (
        <div className="rapoarteHeaderText">{getDenumireForTip(tipRaport)}</div>
      )}
      <div className="acteNormativeListContainer">
        {items.map(item => (
          <div className="acteNormativeRow">
            <span className="bullet hideOnMobile"></span>
            <div className="text">{item.titlu}</div>
            <a href={item.link} className="link" target="_blank">
              {svgs.download}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
