import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { WindowLocation } from "@reach/router"
import NewSelect from "../../components/CereriNew/NewSelect"
import Footer from "../../components/Footer"
import SiteMenu from "../../components/siteMenu"
import "./style.css"

import NewContactForm from "./NewContactForm"
export const plInfo = [
  {
    denumire: "Miroslava",
    adresa: "Miroslava (IS), Str. Constantin Langa nr. 60, Cladirea C1",
    program: "Luni - Vineri 08:00 - 16:00",
    telfax: "0232 24 24 66",
    email: "office",
    serviceCentrale: "0752 029 903",
    dispecerat: "0752 029 911",
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2713.5959374727104!2d27.52446411626066!3d47.146181727661535!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40cafb24c82ced35%3A0x2d5fe53b63178d!2sGAZMIR!5e0!3m2!1sen!2sro!4v1613082033119!5m2!1sen!2sro",
  },
  {
    denumire: "Panciu",
    adresa: "Panciu (VN), Bd. Independenței nr. 28 bl. 24, parter",
    program: "Luni - Vineri 08:00 - 16:00",
    telfax: "0237 275 200",
    email: "panciu",
    serviceCentrale: "0752 02 99 01",
    dispecerat: "0237 275 200",
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11104.603670344062!2d27.0961196!3d45.9082924!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa73784c3df3a798d!2sGazmir%20Panciu!5e0!3m2!1sro!2sro!4v1646073261560!5m2!1sro!2sro",
  },
  {
    denumire: "Odobești",
    adresa:
      "Odobești (VN), Str. Constantin Dobrogeanu Gherea nr. 4 bl. A3, parter",
    program: "Luni - Vineri 08:00 - 16:00",
    telfax: "0755 740 098",
    email: "odobesti",
    serviceCentrale: "-",
    dispecerat: "0755 740 097",
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11134.080771195093!2d27.0657004!3d45.7607642!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x80622989131f87ba!2sGazmir%20Odobesti!5e0!3m2!1sro!2sro!4v1646073317996!5m2!1sro!2sro",
  },
  {
    denumire: "Chirnogi",
    adresa: "Chirnogi (CL), Com. Chirnogi, Str Principală nr. 111",
    program: "Luni - Vineri 08:00 - 16:00",
    telfax: "0242 524 300",
    email: "chirnogi",
    serviceCentrale: "-",
    dispecerat: "0752 029 912",
    iframeSrc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2864.77501902377!2d26.57155805702296!3d44.10863404356046!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ae2fb2e3049639%3A0x4434ac87860506a!2sGAZMIR!5e0!3m2!1sen!2sro!4v1613082298049!5m2!1sen!2sro",
  },
]

type PropTypes = {
  location: WindowLocation
  defaultIndex?: number
}
export default function ContactNew({ defaultIndex = 0, location }: PropTypes) {
  const [plIndex, setPlIndex] = React.useState(defaultIndex)

  return [
    <div key={1} id="pageCentreDePlata" className="whitePage">
      {/* @ts-ignore */}
      <Helmet>
        <title>Contact - Gazmir</title>
        <link rel="canonical" href="https://gazmir.ro/contact" />
        <meta
          name="description"
          content="Contactează-ne folosind formularul de contact online. Gazmir aduce căldura caminului tau!"
        />
        <meta
          name="keywords"
          content="Contact gazmir,Gazmir contact,Gazmir,Informatii gazmir,Gazmir informatii,Date contact gazmir,Adresa gazmir,Gazmir adresa,sediu Gazmir,Gazmir sediu,gazmir locatie,Caserie gazmir,Gazmir caserie,ghiseu gazmir"
        />
      </Helmet>
      <SiteMenu location={location} showPaymentRedirectLoading={false} />
      <div id="paginaContactNew" className="pageContent">
        <div className="pageHeader">
          <div className="pageTitle">Contactează-ne</div>
          <div className="pageSubtitle">
            Trimite un mesaj și îți răspundem în cel mai scurt timp posibil.
          </div>
        </div>

        <div className="pageRow">
          <div className="pageColumn">
            <div className="selectColumn">
              <NewSelect
                items={plInfo.map((pl, index) => ({
                  id: index,
                  denumire: pl.denumire,
                }))}
                selectedIndex={plIndex}
                selectIndex={setPlIndex}
                noSelectionText="Selectează localitatea"
                selectLabel="Selectează localitatea"
              />
            </div>
            <div className="ncPlInfo">
              <div className="infoColumn">
                <div className="ncDispeceratRow forMobile">
                  <div className="label">Dispecerat Intervenții:</div>
                  <div className="value dispecerat">
                    {plInfo[plIndex].dispecerat}
                  </div>
                </div>
                <div className="icRow">
                  <div className="label">Adresă</div>
                  <div className="value adresa">{plInfo[plIndex].adresa}</div>
                </div>

                <div className="icRow">
                  <div className="label">Număr telefon</div>
                  <div className="value telfax">
                    <a href={`tel:${plInfo[plIndex].telfax}`}>
                      {plInfo[plIndex].telfax}
                    </a>
                  </div>
                </div>
                <div className="icRow">
                  <div className="label">Program cu publicul</div>
                  <div className="value program">{plInfo[plIndex].program}</div>
                </div>
                <div className="icRow">
                  <div className="label">Email</div>
                  <div className="value email">
                    <a href={`mailto:${plInfo[plIndex].email}@gazmir.ro`}>
                      {`${plInfo[plIndex].email}@gazmir.ro`}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="ncDispeceratRow">
              <div className="label">Dispecerat Intervenții:</div>
              <div className="value dispecerat">
                {plInfo[plIndex].dispecerat}
              </div>
            </div>
            <div className="mapRow">
              <iframe
                className="googleMap"
                src={plInfo[plIndex].iframeSrc}
                width="100%"
                height="100%"
                frameBorder="0"
                style={{
                  borderBottom: "1px solid rgba(0, 47, 73, 0.5)",
                  marginBottom: 0,
                  borderRadius: 8,
                }}
                allowFullScreen={true}
                aria-hidden={false}
                tabIndex={0}
              ></iframe>
            </div>
          </div>
          <div className="pageColumn formColumn whiteBoxWithShadow">
            <NewContactForm />
          </div>
        </div>
      </div>
    </div>,
    <Footer key={2} containerClassName="homeFooter" />,
  ]
}
