import { getToken } from "../services/keepAuth"
import { ApiResponse, apiBase, checkStatus } from "./utils"

const baseUrl = `${apiBase}/api/general`

interface ApiResponseForFiles<T> extends ApiResponse<T> {
  filesToLarge?: boolean
}

export function postFormularContact(
  data: FormData
): Promise<ApiResponseForFiles<null>> {
  const loginData = {
    method: "post",
    headers: {
      azza: getToken(),
      Accept: "application/json",
    },
    body: data,
  }
  return fetch(`${baseUrl}/contact`, loginData).then(checkStatus) as Promise<
    ApiResponseForFiles<null>
  >
}

export function postPlangeriSesizari(
  data: FormData
): Promise<ApiResponseForFiles<null>> {
  const loginData = {
    method: "post",
    headers: {
      azza: getToken(),
      Accept: "application/json",
    },
    body: data,
  }
  return fetch(`${baseUrl}/plangeriSesizari`, loginData).then(
    checkStatus
  ) as Promise<ApiResponseForFiles<null>>
}
