import React, { FormEvent } from "react"
import Button from "../../components/Button"
import FormInput from "../../components/FormInput"
import InfoMsg, { InfoMsgType } from "../../components/InfoMsg"
import { getLinkInviteData } from "../../utils/api/login"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  CONFIRM_PASSWORD_ERROR,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PASSWORD_ERROR,
  PASSWORD_REGEX,
} from "../../utils/regex"
import { Link } from "gatsby"

export type SignUpFormData = {
  codAbonat: string
  nrContract: string
  email: string
  parola: string
  parolaConfirm: string
}

type PropTypes = {
  infoMsg: InfoMsgType
  tryUserSignUp: (data: SignUpFormData) => void
  invitationId: string | null
}

type DecryptedTokenData = {
  codClient?: string
  nrContract?: string
  email?: string
}

const enableSendButton = ({
  codAbonat,
  nrContract,
  email,
  parola,
  parolaConfirm,
}) =>
  ALFA_NUMERIC_REGEX(codAbonat) &&
  ALFA_NUMERIC_REGEX(nrContract) &&
  EMAIL_REGEX(email) &&
  PASSWORD_REGEX(parola) &&
  parola === parolaConfirm

export default function NewSignupForm({
  infoMsg,
  invitationId,
  tryUserSignUp,
}: PropTypes) {
  const [formData, setFormData] = React.useState<SignUpFormData>({
    codAbonat: "",
    nrContract: "",
    email: "",
    parola: "",
    parolaConfirm: "",
  })

  React.useEffect(() => {
    const tryFetchInviteData = async (invitationId: string) => {
      try {
        const apiResponse = await getLinkInviteData(invitationId)
        console.log(apiResponse)
        if (apiResponse.type) {
          setFormData({
            codAbonat: apiResponse.data.codClient,
            nrContract: apiResponse.data.nrContract,
            email: apiResponse.data.email,
            parola: "",
            parolaConfirm: "",
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
    if (invitationId != null) tryFetchInviteData(invitationId)
  }, [invitationId])

  const handleInputChange = (value, name) => {
    // console.log("Changing form value: ", value, name)
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const testConfirmPassword = parolaConfirm =>
    formData["parola"] === parolaConfirm

  const submitForm = (e: FormEvent) => {
    e.preventDefault()
    tryUserSignUp(formData)
  }

  return (
    <form id="newSignUpForm" className="newStyleForm" onSubmit={submitForm}>
      <FormInput
        containerClassName="newStyleInput"
        type="text"
        label="Cod client"
        name="codAbonat"
        disabled={invitationId != null}
        onChange={handleInputChange}
        value={formData.codAbonat}
        errorTest={ALFA_NUMERIC_REGEX}
        errorMessage={ALFA_NUMERIC_ERROR}
      />
      <FormInput
        containerClassName="newStyleInput"
        type="text"
        label="Număr contract"
        name="nrContract"
        disabled={invitationId != null}
        onChange={handleInputChange}
        value={formData.nrContract}
        errorTest={ALFA_NUMERIC_REGEX}
        errorMessage={ALFA_NUMERIC_ERROR}
      />
      <FormInput
        autoComplete="email-signUp"
        containerClassName="newStyleInput"
        type="email"
        label="Email"
        name="email"
        disabled={invitationId != null}
        onChange={handleInputChange}
        value={formData.email}
        errorTest={EMAIL_REGEX}
        errorMessage={EMAIL_ERROR}
      />
      <FormInput
        autoComplete="new-password-signUp"
        containerClassName="newStyleInput"
        type="password"
        label="Parolă"
        name="parola"
        onChange={handleInputChange}
        value={formData.parola}
        errorTest={PASSWORD_REGEX}
        errorMessage={PASSWORD_ERROR}
      />

      <FormInput
        autoComplete="new-password-signUp"
        containerClassName="newStyleInput"
        type="password"
        label="Confirmare parolă"
        name="parolaConfirm"
        onChange={handleInputChange}
        value={formData.parolaConfirm}
        errorTest={testConfirmPassword}
        errorMessage={CONFIRM_PASSWORD_ERROR}
      />

      <InfoMsg containerClassName="row" infoMsg={infoMsg} />
      <div className="row rowButton">
        <Button
          isLoading={infoMsg.isLoading}
          as="button"
          type="submit"
          label="Creează cont"
          className="newStyleButton"
          aspect="primary"
          disabled={!enableSendButton(formData)}
          // onClick={submitForm}
        />
      </div>
      <div className="row">
        <div className="hintText">
          Probleme? Folosiți formularul de{" "}
          <Link to="/contact">
            <b>contact</b>
          </Link>
        </div>
      </div>
    </form>
  )
}
