import React, { useRef, useState } from "react"
import FormInput from "../../../components/FormInput"
import { colors } from "../../../styles/globals"
import { checkIsFacturaCurenta, dateToDesiredFormat } from "../../../utils"
import { postPreparePaymentData } from "../../../utils/api/payments"
import { AMOUNT_ERROR, AMOUNT_REGEX } from "../../../utils/regex"
import { getUserInfo } from "../../../utils/services/keepAuth"
import PaymentForm from "../../PaymentForm"
import TabelActionCell from "./TabelActionCell"

// const DAYS_INMS = 1000 * 60 * 60 * 24

const styles = {
  input: {
    border: "none",
    // marginLeft: 10,
    marginTop: 10,
    height: 36,
    width: "calc(100% - 10px)",
    fontSize: 20,
    paddingLeft: 10,
    borderRadius: 6,
  },
  inputError: {
    float: "left",
    color: colors.red,
    marginTop: 8,
    marginBottom: 8,
  },
  payText: {
    width: "33%",
    overflow: "hidden",
    // marginLeft: 10,
    float: "left",
    fontSize: 20,
    color: colors.white,
    lineHeight: "56px",
  },
  sumaDoritaContainer: {
    float: "left",
    // width: "calc(50% - 10px)",
    width: "50%",
    height: 56,
    // marginLeft: 10,
    textAlign: "center",
  },
  button: {
    width: "30%",
    height: 36,
    marginTop: 10,
    borderRadius: 6,
    fontSize: 20,
    fontWeight: 700,
    lineHeight: "24px",
    color: colors.white,
  },
}

// const getZileRamase = dataScadentei => {
//   return parseInt(
//     (new Date(dataScadentei).getTime() - new Date().getTime()) / DAYS_INMS
//   )
// }

export const getNrFacturiStinse = (
  facturiRestante,
  sumaPlata,
  nrFacReferintaPlata
) => {
  let floatSumaPlata = 0
  if (typeof sumaPlata === "number") floatSumaPlata = sumaPlata
  else if (typeof sumaPlata === "string")
    floatSumaPlata = parseFloat(sumaPlata.replace(",", "."))
  // console.log(floatSumaPlata)floatSumaPlata
  const nrFacStinse = []
  if (facturiRestante && facturiRestante.length > 0) {
    const facturiReverse = facturiRestante.concat([]).reverse()
    for (var i = 0; i < facturiReverse.length && floatSumaPlata > 0; i++) {
      nrFacStinse.push({
        nrFac: facturiReverse[i].nr_factura,
        iddocei: facturiReverse[i].id_factura,
      })
      floatSumaPlata -= facturiReverse[i].rest_plata
    }
  } else if (nrFacReferintaPlata) nrFacStinse.push(nrFacReferintaPlata)
  return nrFacStinse
}
const getSoldTotal = facturi => {
  return facturi.reduce((acc, item) => acc + item.rest_plata, 0)
}

export default function TabelRestante({
  facturi,
  seeFact,
  setShowPaymentRedirectLoading,
  nrFacReferintaPlata,
}) {
  const [sumaDorita, setSumaDorita] = useState("")
  const [sumaAvans, setSumaAvans] = useState("")

  const formDoritaRef = useRef()
  const jsonRequestDoritaRef = useRef()
  const checksumDoritaRef = useRef()

  const formTotalRef = useRef()
  const jsonRequestTotalRef = useRef()
  const checksumTotalRef = useRef()

  const paySumaDorita = async () => {
    try {
      const apiResponse = await postPreparePaymentData({
        suma: parseFloat(sumaDorita.replace(",", ".")),
        facturiPlatite: getNrFacturiStinse(
          facturi,
          sumaDorita,
          nrFacReferintaPlata
        ),
        descriere:
          facturi.length === 0 ? "Plată avans" : "Plată sumă dorită facturi.",
        ...getUserInfo(),
      })
      if (apiResponse.type) {
        jsonRequestDoritaRef.current.value = apiResponse.data.base64JsonRequest
        checksumDoritaRef.current.value = apiResponse.data.base64Checksum
        setShowPaymentRedirectLoading(true)
        formDoritaRef.current.submit()
      }
    } catch (error) {
      console.log(error)
    }
  }

  // console.log(getNrFacturiStinse(facturi, sumaDorita, nrFacReferintaPlata))
  // console.log(getNrFacturiStinse(facturi, sumaDorita, nrFacReferintaPlata))
  const paySoldTotal = async () => {
    try {
      console.log(getSoldTotal(facturi))
      const apiResponse = await postPreparePaymentData({
        suma: getSoldTotal(facturi),
        descriere: "Plată sold întreg facturi.",
        facturiPlatite: getNrFacturiStinse(
          facturi,
          getSoldTotal(facturi),
          nrFacReferintaPlata
        ),
        ...getUserInfo(),
      })
      if (apiResponse.type) {
        jsonRequestTotalRef.current.value = apiResponse.data.base64JsonRequest
        checksumTotalRef.current.value = apiResponse.data.base64Checksum
        setShowPaymentRedirectLoading(true)
        formTotalRef.current.submit()
      }
    } catch (error) {
      console.log(error)
    }
  }

  // console.log(parseFloat(sumaDorita.replace(",", ".")))
  // console.log(nrFacReferintaPlata)
  // console.log(getNrFacturiStinse(facturi, sumaDorita, nrFacReferintaPlata))
  // console.log(facturi)

  if (!facturi) return false
  else if (facturi.length === 0)
    return (
      <div className="frAvansContainer">
        <div
          className="frAvansText"
          // style={{
          //   marginTop: 32,
          //   textAlign: "center",
          //   margin: "40px auto 0px auto",
          //   color: colors.main,
          //   maxWidth: 920,
          //   fontSize: 24,
          // }}
        >
          <div>
            Nu aveți nici o <b>factură restantă</b>
          </div>
        </div>
      </div>
    )
  else
    return (
      <div>
        <table
          className="darkTable tableFacturi fixedHeader tabelRestante tabelStyle"
          // style={{
          //   textAlign: "center",
          //   width: "100%",
          //   // padding: "0px 1px",
          //   color: colors.main,
          //   borderCollapse: "collapse",
          //   fontSize: 20,
          //   tableLayout: "fixed",
          //   overflowY: "auto",
          //   // border: "1px solid " + colors.main,
          // }}
        >
          <thead>
            <tr>
              <th
                className="tabelHeaderDinamicText"
                data-desktop-label="Număr factură"
                data-mobile-label="Factură"
              ></th>
              <th
                className="tabelHeaderDinamicText"
                data-desktop-label="Dată emitere"
                data-mobile-label="Emitere"
              ></th>
              <th
                className="tabelHeaderDinamicText tabelCellHiddenOnMobile"
                data-desktop-label="Dată scadență"
                data-mobile-label="Scadență"
              ></th>
              {/* <th>Zile rămase</th> */}
              <th
                className="tabelHeaderDinamicText"
                data-desktop-label="Valoare (Lei)"
                data-mobile-label="Valoare"
              ></th>
              <th
                className="tabelHeaderDinamicText"
                data-desktop-label="Rest de plată"
                data-mobile-label="Neachitat"
              ></th>

              {/* <th>Statut plată</th> */}
              <th>Descarcă</th>
            </tr>
          </thead>
          <tbody style={{ height: "auto" }}>
            {facturi.map(item => (
              <tr key={item.id_factura}>
                <td>
                  {item.nr_factura.split("/")[1]
                    ? item.nr_factura.split("/")[1]
                    : item.nr_factura.split("/")[0]}
                </td>
                <td>{dateToDesiredFormat(new Date(item.data_fact))}</td>
                <td className="tabelCellHiddenOnMobile">
                  {dateToDesiredFormat(new Date(item.data_scadentei))}
                </td>
                {/* <td>{getZileRamase(item.data_scadentei)}</td> */}
                <td>
                  {item.total_factura.toLocaleString("ro-RO", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                <td>
                  {item.rest_plata.toLocaleString("ro-RO", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </td>
                {/* <td>{"Restant"}</td> */}
                <TabelActionCell
                  hasPdf={item.hasPdfFactura}
                  onClick={
                    item.hasPdfFactura
                      ? () => seeFact(item.nr_factura)
                      : undefined
                  }
                  isFacturaCurenta={checkIsFacturaCurenta(item.data_fact)}
                />
              </tr>
            ))}
          </tbody>
        </table>
        <div className="sumaSoldContainer">
          <div className="sumaDoritaContainer">
            <div className="payText">Sumă dorită</div>
            <FormInput
              containerClassName="sumaInputContainer"
              className="whitePlaceholder sumaInput"
              // containerStyle={{ float: "left", width: "34%" }}
              style={{
                ...styles.input,
                color: colors.white,
                backgroundColor: colors.secondGray,
              }}
              // errorStyle={styles.inputError}
              type="text"
              placeholder="Introdu suma"
              name="indexNou"
              onChange={setSumaDorita}
              value={sumaDorita}
              errorTest={AMOUNT_REGEX}
              errorMessage={AMOUNT_ERROR}
              showErrorText={false}
            />

            <PaymentForm
              formClassName="formPaySuma"
              formRef={formDoritaRef}
              jsonRequestRef={jsonRequestDoritaRef}
              checksumRef={checksumDoritaRef}
              buttonClassName="subMenuHover paySumaButton"
              buttonStyles={{
                cursor: AMOUNT_REGEX(sumaDorita) ? "pointer" : "initial",
              }}
              disabled={
                !AMOUNT_REGEX(sumaDorita) ||
                sumaDorita == null ||
                parseFloat(sumaDorita.replace(",", ".")) <= 0
              }
              buttonLabel="Plătește"
              makePayment={paySumaDorita}
            />
            {/* <button
            style={{
              ...styles.button,
              backgroundColor: colors.secondGray,
              cursor: AMOUNT_REGEX(sumaDorita) ? "pointer" : "initial",
            }}
            disabled={!AMOUNT_REGEX(sumaDorita)}
            onClick={paySumaDorita}
          >
            Plătește
          </button> */}
          </div>
          <div
            className="sumaDoritaContainer"
            style={{
              // ...styles.sumaDoritaContainer,
              backgroundColor: colors.mainGray,
            }}
          >
            <div className="payText">Sold total</div>
            <FormInput
              containerClassName="sumaInputContainer"
              className="whitePlaceholder sumaInput"
              type="text"
              placeholder="Sumă"
              name="indexNou"
              // onChange={setSumaDorita}
              value={getSoldTotal(facturi).toLocaleString("ro-RO", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              disabled
              showErrorText={false}
              // errorTest={AMOUNT_REGEX}
              // errorMessage={AMOUNT_ERROR}
              // showErrorText={false}
            />
            <PaymentForm
              formClassName="formPaySuma"
              formRef={formTotalRef}
              jsonRequestRef={jsonRequestTotalRef}
              checksumRef={checksumTotalRef}
              buttonClassName="subMenuHover paySumaButton"
              buttonStyles={{
                // ...styles.button,
                backgroundColor: colors.main,
                cursor: getSoldTotal(facturi) > 0 ? "pointer" : "initial",
              }}
              disabled={!getSoldTotal(facturi) > 0}
              buttonLabel="Plătește"
              makePayment={paySoldTotal}
            />
            {/* <button
            style={{
              ...styles.button,
              backgroundColor: colors.main,
              cursor: getSoldTotal(facturi) > 0 ? "pointer" : "initial",
            }}
            disabled={!getSoldTotal(facturi) > 0}
            onClick={paySoldTotal}
          >
            Plătește
          </button> */}
          </div>
        </div>
        {sumaDorita > getSoldTotal(facturi) && (
          <div
            style={{
              marginTop: 32,
              textAlign: "center",
              margin: "32px auto 0px auto",
              color: colors.main,
              maxWidth: 700,
              fontSize: 20,
            }}
          >
            {"Ați introdus o sumă mai mare decât soldul datorat, diferența de "}
            <b>
              {(sumaDorita - getSoldTotal(facturi)).toLocaleString("ro-RO", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </b>
            {" lei va fi tratată ca fiind o plată în avans"}
          </div>
        )}
      </div>
    )
}
