import React from "react"
import { postFormularContact } from "../../utils/api/general"
import {
  ALFA_NUMERIC_ERROR,
  ALFA_NUMERIC_REGEX,
  EMAIL_ERROR,
  EMAIL_REGEX,
  PHONENUMBER_ERROR,
  PHONENUMBER_REGEX,
} from "../../utils/regex"
import Button from "../Button"
import { calcFilesSize } from "../Cereri/MultiFileUploader"
import CheckBoxAgreeToTerms from "../CereriNew/CheckBoxAgreeToTerms"
import FormeErrorLine from "../CereriNew/FormErrorLine"
import NewFormFileInput from "../CereriNew/FormFileInput"
import NewTextArea from "../CereriNew/NewTextArea"
import FormInput from "../FormInput"
import { useFileInput } from "../Hooks/useFileInput"
import InfoMsg, { InfoMsgType } from "../InfoMsg"

type FormData = {
  denumire: string
  telefon: string
  email: string
  mesaj: string
  agreedToTerms: boolean
}

function getFormErrorMessage(formData: FormData, files: File[]) {
  if (
    !(
      ALFA_NUMERIC_REGEX(formData.denumire) &&
      EMAIL_REGEX(formData.email) &&
      PHONENUMBER_REGEX(formData.telefon) &&
      ALFA_NUMERIC_REGEX(formData.mesaj) &&
      formData.agreedToTerms
    )
  )
    return "Formularul conține erori"
  else if (files.length > 0 && calcFilesSize(files) > 14.99)
    return "Dimensiunea totală a fișierelor depășește 14.99 MB"
  else return ""
}

function isButtonEnabled(formData: FormData, files: File[]) {
  return (
    (files.length === 0 ||
      (files.length > 0 && calcFilesSize(files) < 14.99)) &&
    ALFA_NUMERIC_REGEX(formData.denumire) &&
    EMAIL_REGEX(formData.email) &&
    PHONENUMBER_REGEX(formData.telefon) &&
    ALFA_NUMERIC_REGEX(formData.mesaj) &&
    formData.agreedToTerms
  )
}

export default function NewContactForm() {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: false,
  })
  const [nrHover, setNrHover] = React.useState(0)
  const [formData, setFormData] = React.useState<FormData>({
    denumire: "",
    telefon: "",
    email: "",
    mesaj: "",
    agreedToTerms: false,
  })

  const fileInputRef = React.useRef<HTMLInputElement | null>(null)
  const { files, removeFile, resetFiles, changeFiles, uploadFiles } =
    useFileInput(fileInputRef)

  const handleFormDataChange = (value: string, name: string) => {
    setFormData(oldFormData => ({
      ...oldFormData,
      [name]: value,
    }))
  }

  const handleSubmit = async () => {
    setInfoMsg({
      isLoading: true,
    })
    const data = new FormData()
    for (var i in files) data.append("file", files[i])
    data.append("denumire", formData.denumire)
    data.append("email", formData.email)
    data.append("telefon", formData.telefon)
    data.append("descriere", formData.mesaj)
    try {
      const apiResponse = await postFormularContact(data)
      // console.log(apiResponse)

      if (apiResponse.filesToLarge)
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "A apărut o eroare la încarcarea fișierelor. Acestea nu trebuie să depășească 20MB",
        })
      else if (apiResponse.type) {
        // setShowErrorHints(false)
        setInfoMsg({
          isLoading: false,
          status: "ok",
          msg: "Mesajul tău a fost transmis cu succes!",
        })
      } else
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "A apărut o eroare la salvarea datelor. Te rugăm să reîncerci sau să ne contactezi pentru a remedia problema",
        })
    } catch (error) {
      console.log(error)
      setInfoMsg({
        isLoading: false,
        status: "error",
        msg: "A apărut o eroare la salvarea datelor. Te rugăm să reîncerci sau să ne contactezi pentru a remedia problema",
      })
    } finally {
      // divRef.current.scrollIntoView()
      // divRef.current.scrollTop =
      // divRef.current.scrollHeight - divRef.current.clientHeight
    }
  }

  return (
    <form id="newFormContact" className="newCereriContainer">
      {/* @ts-ignore */}
      <FormInput
        containerClassName="ncsInputContainer"
        label={"Nume / Denumire"}
        placeholder={"Nume / Denumire"}
        name="denumire"
        onChange={handleFormDataChange}
        value={formData.denumire}
        errorTest={ALFA_NUMERIC_REGEX}
        errorMessage={ALFA_NUMERIC_ERROR}
        showWithoutTouched={nrHover > 1}
      />

      <div className="pageRow">
        {/* @ts-ignore */}
        <FormInput
          containerClassName="ncsInputContainer"
          label="Email"
          placeholder="Email"
          name="email"
          onChange={handleFormDataChange}
          value={formData.email}
          errorTest={EMAIL_REGEX}
          errorMessage={EMAIL_ERROR}
          showWithoutTouched={nrHover > 1}
        />
        {/* @ts-ignore */}
        <FormInput
          containerClassName="ncsInputContainer"
          label="Număr de telefon"
          placeholder="Număr de telefon"
          name="telefon"
          onChange={handleFormDataChange}
          value={formData.telefon}
          errorTest={PHONENUMBER_REGEX}
          errorMessage={PHONENUMBER_ERROR}
          showWithoutTouched={nrHover > 1}
        />
      </div>
      <NewTextArea
        containerClassName="ncsInputContainer"
        label="Mesaj"
        placeholder="Scrieți o scurtă descriere despre informațiile pe care doriți să le obțineți"
        name="mesaj"
        onChange={handleFormDataChange}
        value={formData.mesaj}
        errorTest={ALFA_NUMERIC_REGEX}
        errorMessage={ALFA_NUMERIC_ERROR}
        showWithoutTouched={nrHover > 1}
      />
      <NewFormFileInput
        files={files}
        changeFiles={changeFiles}
        removeFile={removeFile}
        fileInputRef={fileInputRef}
        id="fileInputracordare"
        buttonLabel="Anexează documente (opțional)"
        hideButton={false}
      />

      <CheckBoxAgreeToTerms
        id="policyCheck"
        checked={formData.agreedToTerms}
        onChange={() =>
          setFormData(oldData => ({
            ...oldData,
            agreedToTerms: !oldData.agreedToTerms,
          }))
        }
        showError={nrHover > 1 && !formData.agreedToTerms}
      />
      <FormeErrorLine
        status="error"
        errorMessage={getFormErrorMessage(formData, files)}
        doesFormHaveErrors={!isButtonEnabled(formData, files)}
        nrHover={nrHover}
        setNrHover={setNrHover}
      />
      <InfoMsg
        containerClassName={
          infoMsg.status === "ok" ? "stepTitle" : "marginTop24 "
        }
        infoMsg={infoMsg}
      />
      {infoMsg.status !== "ok" && (
        <div className="pageRow ncsButtonRow">
          <Button
            as={"button"}
            aspect="noBackground"
            className="ncsButton"
            label={"Încarcă documente"}
            onClick={() => {
              if (fileInputRef.current != null) fileInputRef.current.click()
            }}
          />
          <Button
            isLoading={infoMsg.isLoading}
            as={"button"}
            disabled={!isButtonEnabled(formData, files)}
            aspect="primary"
            className="ncsButton"
            label={"Trimite"}
            onClick={handleSubmit}
          />
        </div>
      )}
    </form>
  )
}
