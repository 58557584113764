import React, { useEffect, useRef, useState } from "react"
import { colors } from "../../styles/globals"
import { getUltimulIndex } from "../../utils/api/sivap"
import { getContracts } from "../../utils/services/keepAuth"
import FormInput from "../FormInput"
import Spinner from "../Spinner"
import { cereriStyles } from "../Cereri/styles"
import { NUMERIC_ERROR, NUMERIC_REGEX } from "../../utils/regex"
import { postAutoCitire } from "../../utils/api/autocitire"

function isLast5DaysOfMonth() {
  // return true
  // const dataAzi = new Date()
  // const currentMonth = dataAzi.getMonth()
  // dataAzi.setDate(dataAzi.getDate() + 5)
  // if(currentMonth === 11 && dataAzi.getMonth())
  // return dataAzi.getMonth() > currentMonth
  const dataAzi = new Date()
  const lastDayOfMonth = new Date(
    dataAzi.getFullYear(),
    dataAzi.getMonth() + 1,
    0,
    10
  )
  // console.log(dataAzi.getDate(), lastDayOfMonth.getDate(), lastDayOfMonth.getDate() - 5);
  return (
    dataAzi.getDate() > lastDayOfMonth.getDate() - 5 || dataAzi.getDate() < 2
  )
}

export default function AutoCitire() {
  const [isLoading, setIsLoading] = useState(true)
  const [showSelectClc, setShowSelectClc] = useState(false)
  const [clcIndex, setClcIndex] = useState(null)
  const [indexVechi, setIndexVechi] = useState(null)
  const [indexNou, setIndexNou] = useState("")
  const [infoMsg, setInfoMsg] = useState(null)
  const [contracts, setContracts] = useState([])
  const [showInfoMsg, setShowInfoMsg] = useState(false)

  const codContor = useRef(null)

  // const fetchDateAutocitire = async () => {
  // try {
  //   const soldResponse = await getSoldClient()
  //   console.log(typeof soldResponse.data.sold)
  //   if (soldResponse.type)
  //     setSold(
  //       soldResponse.data.sold.toLocaleString("ro-RO", {
  //         minimumFractionDigits: 2,
  //         maximumFractionDigits: 2,
  //       })
  //     )
  // } catch (error) {
  //   console.log(error)
  //   setSold("Indisponibil")
  // }
  // }

  useEffect(() => {
    // fetchDateAutocitire()
    // console.log(!!getContracts())
    const fetchLastIndex = async () => {
      try {
        const apiResponse = await getUltimulIndex()
        console.log(apiResponse)
        if (apiResponse.type) {
          setIndexVechi(apiResponse.data.indexVechi)
          codContor.current = apiResponse.data.codContor
        } else setIndexVechi("Indisponibil")
      } catch (error) {
        console.error(error)
        setIndexVechi("Indisponibil")
      } finally {
        setIsLoading(false)
      }
    }
    const contracts = getContracts()
    if (contracts != null) {
      setContracts(contracts)
      setClcIndex(0)
      fetchLastIndex()
    }
  }, [])

  // useEffect(() => {

  // }, [contracts, clcIndex])

  // useEffect(() => {
  //   if (indexVechi > indexNou)
  //     setInfoMsg(
  //       "Indexul introdus este mai mic decât cel vechi! Verificați că ați introdus corect indexul înainte de a transmite autocitirea"
  //     )
  //   else setInfoMsg("")
  // }, [indexNou])

  const checkNewIndex = () => {
    setShowInfoMsg(true)
  }

  const handleIndexNouChange = value => {
    setIndexNou(value)
  }

  const sendAutocitire = async () => {
    setInfoMsg("Vă rugăm așteptați")
    setTimeout(async () => {
      try {
        const apiReponse = await postAutoCitire({
          codContor: codContor.current,
          indexNou,
          indexVechi,
        })
        if (apiReponse.type) setInfoMsg("Autocitire transmisă cu succes")
        else setInfoMsg("Eroare la transmiterea autocitirii")
        // console.log(apiReponse)
      } catch (error) {
        setInfoMsg("Eroare la transmiterea autocitirii")
        console.log(error)
      }
    }, 2000)
  }

  return (
    <div className="autoCitireContainer">
      {!isLast5DaysOfMonth() && (
        <div
          className="autoCitireTitle"
          // style={{
          //   color: colors.newOrange,
          //   fontSize: 24,
          //   // height: 40,
          //   padding: "20px 0px",
          //   lineHeight: "30px",
          //   // paddingTop: 12,
          //   // paddingBottom: 12,
          //   textAlign: "center",
          //   marginTop: isLast5DaysOfMonth() ? 0 : 180,
          // }}
        >
          {/* <div className="autoCitireSubTitle"> */}
          Transmiterea autocitirii se poate realiza <br /> doar în ultimele 5
          zile ale lunii
          {/* </div> */}
        </div>
      )}
      {/* <div
          role="button"
          tabIndex={0}
          style={{
            width: 480,
            cursor: "pointer",
          }}
          onClick={toggleSelectClc}
        >
          <div
            style={{
              float: "left",
              color: colors.white,
              fontSize: 20,
              width: 210,
              height: 56,
              lineHeight: "56px",
              marginLeft: 132,
              // marginTop: 18,
            }}
          >
            Alege locul de consum
          </div>
          <div style={{ float: "left", marginTop: 23, marginLeft: 80 }}>
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8 16L16 0H0L8 16Z" fill="white" />
            </svg>
          </div>
          {showSelectClc &&
            contracts.map((item, index) => (
              <div
                style={{
                  display: "inline-block",
                  height: 56,
                  width: 480,
                  fontSize: 16,
                  lineHeight: "56px",
                  textAlign: "center",
                  color: colors.white,
                  backgroundColor: colors.accent,
                }}
                key={index}
                onClick={() => selectClc(index)}
              >
                {item.clc + "-" + item.adresaClc}
              </div>
            ))}
        </div> */}
      {clcIndex !== null && contracts[clcIndex] != null && (
        <div>
          <div className="infoClientRow autoCitireRow">
            <div
              className="infoClientLabel"
              // style={{
              //   float: "left",
              //   height: 56,
              //   width: 216,
              //   color: colors.white,
              //   fontSize: 20,
              //   marginLeft: 24,
              //   lineHeight: "56px",
              // }}
            >
              Cod loc consum
            </div>
            <div
              className="infoClientText"
              // style={{
              //   float: "left",
              //   height: 56,
              //   width: 216,
              //   color: colors.textGrey,
              //   fontSize: 20,
              //   marginLeft: 24,
              //   lineHeight: "56px",
              // }}
            >
              {contracts[clcIndex].clc}
            </div>
          </div>
          <div className="infoClientRow autoCitireRow">
            <div className="infoClientLabel">Adresă loc consum</div>
            <div className="infoClientText capitalizeText">
              {contracts[clcIndex].adresaClc.toLowerCase()}
            </div>
          </div>
          <div className="infoClientRow autoCitireRow">
            <div className="infoClientLabel">Index vechi</div>
            <div className="infoClientText">
              {isLoading ? (
                <Spinner
                  isDark={false}
                  containerStyle={{
                    width: 20,
                    height: 20,
                    margin: "4px 4px 0px 0px",
                  }}
                  show={true}
                />
              ) : (
                indexVechi
              )}
            </div>
          </div>
          <div className="infoClientRow autoCitireRow">
            <div className="infoClientLabel">Index Nou</div>

            <div className="infoClientText">
              <FormInput
                // containerStyle={cereriStyles.inputContainer}
                errorTextClassName="autoCitireInputError"
                className="autoCitireInput"
                // style={{
                //   ...cereriStyles.input,
                //   height: 32,
                //   width: 180,
                //   color: colors.main,
                //   fontSize: 18,
                //   paddingLeft: 4,
                //   borderRadius: 6,
                //   // marginBottom: 12,
                //   // lineHeight: "56px",
                // }}
                type="text"
                placeholder="Introdu"
                name="indexNou"
                onChange={handleIndexNouChange}
                value={indexNou}
                errorTest={NUMERIC_REGEX}
                errorMessage={NUMERIC_ERROR}
                blurHook={checkNewIndex}
                errorStyle={{ ...cereriStyles.inputError, marginTop: 8 }}
                disabled={
                  infoMsg === "Autocitire transmisă cu succes" ||
                  !isLast5DaysOfMonth()
                }
              />
            </div>
          </div>

          {infoMsg && (
            <>
              <Spinner
                isDark={false}
                containerStyle={{
                  width: 40,
                  height: 40,
                  margin: "20px auto 0px auto",
                }}
                show={infoMsg === "Vă rugăm așteptați"}
              />
              {showInfoMsg && (
                <div
                  style={{
                    width: "100%",
                    display: "inline-block",
                    padding: "20px 20px 0px 20px",
                    // marginTop: 32,
                    textAlign: "center",
                    // infoMsg === "Vă rugăm așteptați" ? "center" : "justify",
                    fontSize: 16,
                    color: infoMsg.includes("mai mic")
                      ? colors.newOrange
                      : infoMsg.includes("Eroare")
                      ? colors.red
                      : colors.white,
                  }}
                >
                  {infoMsg}
                </div>
              )}
            </>
          )}
          {infoMsg !== "Vă rugăm așteptați" &&
            infoMsg !== "Autocitire transmisă cu succes" &&
            isLast5DaysOfMonth() && (
              <div className="infoClientRow autoCitireRow">
                <button
                  className="subMenuHover autoCitireButton tabelHeaderDinamicText"
                  data-desktop-label="Transmite"
                  data-mobile-label="Transmite"
                  // style={{
                  //   // float: "left",
                  //   display: "inline-block",
                  //   width: 296,
                  //   height: 32,
                  //   margin: "24px 92px",
                  //   backgroundColor: colors.white,
                  //   textAlign: "center",
                  //   fontSize: 16,
                  //   lineHeight: "32px",
                  //   color: colors.main,
                  //   cursor: "pointer",
                  //   borderRadius: 6,
                  // }}
                  onClick={sendAutocitire}
                  disabled={!NUMERIC_REGEX(indexNou)}
                >
                  {/* Transmite autocitirea */}
                </button>
              </div>
            )}
        </div>
      )}
    </div>
  )
}
